// extracted by mini-css-extract-plugin
export var disabled = "Slider-module--disabled--JXXyY";
export var placeholder = "Slider-module--placeholder--j-UBS";
export var prev = "Slider-module--prev--Eq28m";
export var progress = "Slider-module--progress--I86Cc";
export var progressFill = "Slider-module--progress-fill--UHU6V";
export var progressRail = "Slider-module--progress-rail--H6LfD";
export var slider = "Slider-module--slider--f+XiB";
export var sliderControl = "Slider-module--sliderControl--UJWOw";
export var sliderFadeOutOnExit = "Slider-module--sliderFadeOutOnExit--s34sS";
export var sliderItem = "Slider-module--sliderItem--kbT9M";
export var sliderItemActive = "Slider-module--sliderItemActive--K63l6";
export var sliderNavigation = "Slider-module--sliderNavigation--2cbkD";
export var sliderNavigationAndControlsHeightReset = "Slider-module--sliderNavigationAndControlsHeightReset--joGzk";
export var sliderNavigationItem = "Slider-module--sliderNavigationItem--rQ03-";
export var sliderNavigationItemActive = "Slider-module--sliderNavigationItemActive--iUrJa";
export var sliderRail = "Slider-module--sliderRail--pBmlO";
export var swipe = "Slider-module--swipe--0gMwx";