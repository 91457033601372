import React from "react";
import Headline from "../../components/Headline/Headline";
import ScopriDiPiu from "../../components/ScopriDiPiu/ScopriDiPiu";
//@ts-ignore
import Vector from "../../assets/Vector.png";

//@ts-ignore
import * as styles from "./ServiceParagraph.module.css";

const ServiceParagraph = () => {
  return (
    <section id="servizi" className={styles.container}>
      <div>
        {/* Headline Container */}
        <div className={styles.headlineContainer}>
          <div>
            <Headline
              className={styles.headline}
              headline="Servizi"
              tagName="h2"
            />
            {/* Subtitle */}
            <span className={styles.subTitle}>Cosa Sappiamo Fare ?</span>
          </div>
        </div>

        {/* Content */}
        <div
          className={`
                        grid-8--tablet
                        grid-12--desktop-small 
                        ${styles.content}
                    `}
        >
          <div className={styles.desktopOnRow}>
            {/* ScopriDiPiuContainer */}
            <div className={styles.scopriDiPiuContainer}>
              <ScopriDiPiu
                icon="connectionComputers"
                subTitle="Strategie HR & Change management"
                to="strategie-hr-change-managment"
              />
            </div>
            <div className={styles.scopriDiPiuContainer}>
              <ScopriDiPiu
                icon="freccette"
                subTitle="Competenze Workshop"
                to="/competenze-workshop"
              />
            </div>
          </div>
          <div className={styles.desktopOnRow}>
            <div className={styles.scopriDiPiuContainer}>
              <ScopriDiPiu
                icon="otherCake"
                subTitle="Sistemi di Competenze + modelli di leadership"
                to="competenze-leadership"
              />
            </div>
            <div className={styles.scopriDiPiuContainer}>
              <ScopriDiPiu
                icon="bag"
                subTitle="Assessment Center + Development Center"
                to="/assessment-center"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceParagraph;
