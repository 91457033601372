import React from "react";
import Icon from "../../components/Icon/Icon";

//@ts-ignore
import * as styles from "./ImageRowParagraph.module.css";

const ImageRowParagraph = () => {
  return (
    <div className={`grid-12--desktop-small ${styles.container}`}>
      <Icon icon="connectionComputers" className={styles.computer} />
      <Icon icon="computerSchema" className={styles.schema} />
      <Icon icon="pig" className={styles.pig} />
    </div>
  );
};

export default ImageRowParagraph;
