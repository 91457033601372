import * as React from "react";
import Layout from "../components/Layout/Layout";
import Icon from "../components/Icon/Icon";
import Headline from "../components/Headline/Headline";
import HeaderSliderParagraph from "../_paragraph/HeaderSliderParagraph/ HeaderSliderParagraph";
import ServiceParagraph from "../_paragraph/ServiceParagraph/ServiceParagraph";
import IndexHeroParagraph from "../_paragraph/IndexHeroParagraph/IndexHeroParagraph";
import ImageRowParagraph from "../_paragraph/ImageRowParagraph/ImageRowParagraph";
import FormazioneParagraph from "../_paragraph/FormazioneParagraph/FormazioneParagraph";

import * as styles from "./index.module.css";

const IndexPage = () => {
  const items = [
    {
      src: "tim",
      alt: "tim logo",
      id: "2221",
    },
    {
      src: "alitalia",
      alt: "alitalia logo",
      id: "2222",
    },
    {
      src: "leonardo",
      alt: "leonardo logo",
      id: "2223",
    },
    {
      src: "windtre",
      alt: "wind logo",
      id: "2374",
    },
    {
      src: "almaviva",
      alt: "ALMAVIVA logo",
      id: "2189",
    },
    {
      src: "enel",
      alt: "ENEL logo",
      id: "2235",
    },
    {
      src: "posteItaliane",
      alt: "Poste Italiane logo",
      id: "9327",
    },
    {
      src: "sda",
      alt: "SDA logo",
      id: "1128",
    },
    {
      src: "sky",
      alt: "SKY logo",
      id: "2329",
    },
    {
      src: "vodafone",
      alt: "VODAFONE logo",
      id: "3240",
    },
  ];

  return (
    <Layout>
      {/* IndexHeroParagraph */}
      <IndexHeroParagraph />

      <section id="creiamo-valore" className="grid-12--desktop-small">
        <div
          className={`grid-8--mobile grid-8--tablet grid-offset-4--desktop-small grid-8--desktop-small ${styles.creiamoValoreContainer}`}
        >
          <div className={styles.headlineAndImageContainer}>
            <Headline
              className={`${styles.creiamoValoreHeadline} ${styles.paddingLeft}`}
              headline="Creiamo Valore"
              tagName="h2"
            />

            <Icon className={styles.creiamoValoreImage} icon="strettaDiMano" />
          </div>

          <div
            className={`grid-7--desktop-small ${styles.paragraphsContainer}`}
          >
            <p className={styles.creiamoValoreParagraph}>
              <strong>People & Business Value</strong> è una società con una
              trentennale esperienza nella formazione manageriale: è composta da
              senior consultants, senior trainers, manager e psicologi del
              lavoro con esperienza in contesti differenziati e complessi.
            </p>
            <p className={styles.creiamoValoreParagraph}>
              Il nostro team è unito dalla passione di conoscere e sperimentare
              metodologie innovative e best practices della formazione, della
              valutazione e del coaching.
            </p>
          </div>

          <div
            className={`${styles.headlineImageContainer} ${styles.textAlignCenter}`}
          >
            <Headline
              className={`${styles.creiamoValoreHeadline} ${styles.textAlignCenter}`}
              headline="Come?"
              tagName="h2"
            />

            <div className={styles.uomoIdeaContainer}>
              <img src={"images/uomoIdea.svg"} />
            </div>
          </div>

          <div
            className={`grid-7--desktop-small ${styles.paragraphsContainer}`}
          >
            <p className={styles.creiamoValoreParagraph}>
              <strong>Il Metodo:</strong> Supportiamo il management delle
              risorse umane nello sviluppo di progetti ed interventi disegnati
              “su misura”, in grado di rispondere al meglio agli obiettivi di
              business, secondo modalità coerenti alla cultura aziendale.
            </p>
            <div className={styles.relativeParagraph}>
              <p className={styles.creiamoValoreParagraph}>
                Collaborando con i manager di diversi contesti organizzativi,
                P&B ha messo a punto metodologie in grado di proporre soluzioni
                innovative per lo sviluppo del capitale umano, per supportare
                gli specialisti HR ed i vertici aziendali nella progettazione e
                nella implementazione di una People Strategy allineata con la
                Business Strategy.
              </p>

              <div className={styles.iconBoldContainer} id="icon_tofix">
                <Icon icon="arrowBoldRight" />
              </div>
            </div>
            <p
              className={`grid-7--desktop-small ${styles.creiamoValoreParagraph} ${styles.relativeParagraph}`}
            >
              <strong>La nostra forza:</strong> La nostra caratteristica
              principale è l’attenzione nel comprendere i contesti in cui
              sperimentare metodologie innovative, format, strumenti ed approcci
              non tradizionali, per effettuare azioni formative e diagnosi
              mirate a cambiare il contesto organizzativo e migliorarne la
              performance.
              <div className={styles.iconBoldLeftContainer}>
                <Icon icon="arrowBoldLeft" />
              </div>
            </p>

            <p className={styles.creiamoValoreParagraph}>
              La gestione e lo sviluppo delle risorse umane sono sempre stati il
              nostro core business.
            </p>
          </div>
        </div>
      </section>

      {/* Formazione Paragraph */}
      <FormazioneParagraph />

      {/* Image Row Paragraph visible just on Desktop */}
      <ImageRowParagraph />

      {/* Service Paragraph */}
      <React.Fragment id="servizi">
        <ServiceParagraph />
      </React.Fragment>

      {/* Header Slider Paragraph */}
      <section id="portfolio">
        <HeaderSliderParagraph
          tagName="h2"
          headline="Portfolio"
          subTitle="I nostri principali partner"
          items={items.map((item) => {
            console.log(item);
            return (
              <div key={item.id}>
                <Icon icon={item.src} iconClass="white" />
              </div>
            );
          })}
        />
      </section>
    </Layout>
  );
};

export default IndexPage;
