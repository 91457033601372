import React from "react";
import Button from "../Button/Button";
import { Icons } from "../../_typescript/type";
import Icon from "../Icon/Icon";

//@ts-ignore
import * as styles from "./ScopriDiPiu.module.css";

interface IScopriDiPiu {
  icon: Icons;
  to: string;
  subTitle: string;
}

const ScopriDiPiu = ({ icon, subTitle, to }: IScopriDiPiu) => {
  return (
    <div
      className={`
                    ${styles.container}
                    ${styles.trythis}
                `}
    >
      {/* Image */}
      <Icon className={styles.image} icon={icon} />

      {/* Sub Title */}
      <span
        className={`
                    grid-5--mobile
                    grid-4--tablet
                    grid-4--desktop-small
                    ${styles.subTitle}
                `}
      >
        {subTitle}
      </span>

      <div className={styles.buttonContainer}>
        <Button layout="button" to={to} label="Scopri di più" />
      </div>
    </div>
  );
};

export default ScopriDiPiu;
