//@ts-ignore
import { classNames } from "../../helpers/classes";
//@ts-ignore
import { IconClass } from "../../_typescript/types";
import * as React from "react";
import { useEffect, useState } from "react";
import { useSwipeable } from "react-swipeable";

//@ts-ignore
import * as styles from "./Slider.module.css";

interface ISlider {
  items: JSX.Element[];
  desktopItemsPerSlide?: number;
  tabletItemsPerSlide?: number;
  mobileItemsPerSlide?: number;
  arrowIconRight?: any;
  arrowIconLeft?: any;
  transitionDuration?: number;
  rotate?: boolean;
  swipe?: boolean;
  iconClass?: IconClass;
  className?: string;
  breakpoints?: {
    mobile: number;
    tablet: number;
  };
  navigationAndControlsInside?: boolean;
  debug?: boolean;
  fadeOutOnExit?: boolean;
  // eslint-disable-next-line no-unused-vars
  navigationCallback?: (sliderItem: any) => void;
  showNavigationIfRequired?: boolean;
  showProgressBar?: boolean;
  showOverflow?: boolean;
  autoPlay?: boolean;
  autoPlayInterval?: number;
  sliderNavigationAndControlsHeightReset?: boolean;
}

const Slider = ({
  items,
  desktopItemsPerSlide = 3,
  tabletItemsPerSlide = 3,
  mobileItemsPerSlide = 1,
  arrowIconRight,
  arrowIconLeft,
  transitionDuration = 500,
  rotate = false,
  swipe = true,
  iconClass = "black",
  className = "",
  breakpoints = {
    mobile: 767,
    tablet: 1125,
  },
  navigationAndControlsInside = true,
  debug = false,
  fadeOutOnExit = true,
  navigationCallback, // called whenever a slide changes
  showNavigationIfRequired = true,
  showProgressBar = false,
  showOverflow = false, // display overflowing items to the right and left
  autoPlay = false,
  autoPlayInterval = 5000,
  sliderNavigationAndControlsHeightReset,
}: ISlider) => {
  const responsive =
    (mobileItemsPerSlide + tabletItemsPerSlide + desktopItemsPerSlide) / 3 !==
    desktopItemsPerSlide;
  debug && console.log("[Slider]", "responsive", responsive);

  const [sliderItems, setSliderItems] = useState([] as JSX.Element[]);
  const [sliderNavigationItems, setSliderNavigationItems] = useState(
    [] as number[]
  );
  const [sliderPositionIndex, setSliderPositionIndex] = useState(0);
  const [displayNavigation, setDisplayNavigation] = useState(false);
  const [isCalculating, setIsCalculating] = useState(true);

  /**
   * Calculate slider position index by direction
   *
   * @param {-1|1} direction
   * @returns {int}
   */
  const calcSliderPositionIndex = (direction: -1 | 1) => {
    const theoreticalTargetIndex = sliderPositionIndex + direction;
    console.log(
      "sliderPositionIndex",
      theoreticalTargetIndex,
      sliderItems,
      0 > theoreticalTargetIndex
        ? sliderItems.length - 1
        : theoreticalTargetIndex >= sliderItems.length
        ? 0
        : theoreticalTargetIndex
    );
    switch (rotate) {
      case true:
        return 0 > theoreticalTargetIndex
          ? sliderItems.length - 1
          : theoreticalTargetIndex >= sliderItems.length
          ? 0
          : theoreticalTargetIndex;
      case false:
        return Math.min(
          sliderItems.length - 1,
          Math.max(0, theoreticalTargetIndex)
        );
    }
  };

  /**
   * Distribute slider items
   */
  const distributeSliderItems = () => {
    const distributedItems = [] as number[];
    const newSliderItems = [];

    const calculatedItemsPerSlide = calculateItemsPerSlide();

    // add invisible items at the end of the last slide if it does not contain enough children
    while (0 < items.length % calculatedItemsPerSlide) {
      const emptyPlaceholder = React.cloneElement(items[0], {
        key: items[0].key + String(items.length),
        className: `${items[0].props.className} ${styles.placeholder}`,
      });
      items = [...items, emptyPlaceholder];
    }

    for (const item of items) {
      const index = items.indexOf(item);
      if (
        0 === (index + 1) % calculatedItemsPerSlide ||
        items.length === index + 1
      ) {
        const newSliderItem = (
          <>
            {items.map((item, i) => {
              if (
                i > index - calculatedItemsPerSlide &&
                i <= index &&
                0 > distributedItems.indexOf(i)
              ) {
                distributedItems.push(i);
                return item;
              }
            })}
          </>
        );
        newSliderItems.push(newSliderItem);
      }
    }

    setSliderItems(newSliderItems);
    debug && console.log("[Slider]", "distributeSliderItems", newSliderItems);
  };

  const swipeConfiguration = {
    delta: 10,
    preventDefaultTouchmoveEvent: true,
    trackTouch: true,
    trackMouse: true,
    rotationAngle: 0,
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      setSliderPositionIndex(calcSliderPositionIndex(1));
    },
    onSwipedRight: () => {
      setSliderPositionIndex(calcSliderPositionIndex(-1));
    },
    ...swipeConfiguration,
  });

  let throttler: any = null;

  /**
   * Update items per slide based on responsiveness
   */
  const calculateItemsPerSlide = () => {
    if (!responsive) {
      return desktopItemsPerSlide;
    }
    if (breakpoints.mobile > window.innerWidth) {
      debug && console.log("[Slider]", "itemsPerSlide", mobileItemsPerSlide);
      return mobileItemsPerSlide;
    } else if (
      breakpoints.mobile <= window.innerWidth &&
      breakpoints.tablet > window.innerWidth
    ) {
      debug && console.log("[Slider]", "itemsPerSlide", tabletItemsPerSlide);
      return tabletItemsPerSlide;
    } else if (breakpoints.tablet <= window.innerWidth) {
      debug && console.log("[Slider]", "itemsPerSlide", desktopItemsPerSlide);
      return desktopItemsPerSlide;
    }
    return desktopItemsPerSlide;
  };

  /**
   * Update slider navigation items based on slider items length
   */
  const updateSliderNavigationItems = () => {
    let navItems = [];
    for (let i: number = 0; sliderItems.length > i; i++) {
      navItems.push(i);
    }
    setSliderNavigationItems(navItems);
    debug && console.log("[Slider]", "updateSliderNavigationItems", navItems);
  };

  useEffect(() => {
    if (navigationCallback) {
      navigationCallback(items[sliderPositionIndex]);
    }
  }, [sliderPositionIndex]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      clearTimeout(throttler);
      throttler = setTimeout(() => {
        distributeSliderItems();
        updateSliderNavigationItems();
      }, 250);
    });
  }, []);

  useEffect(() => {
    distributeSliderItems();
  }, [items]);

  useEffect(() => {
    updateSliderNavigationItems();
  }, [items, sliderItems]);

  useEffect(() => {
    updateSliderNavigationItems();
    setDisplayNavigation(1 < sliderItems.length);
  }, [sliderItems]);

  useEffect(() => {
    setTimeout(() => {
      setIsCalculating(false);
    });
  }, []);

  useEffect(() => {
    if (!sliderItems.length) {
      return;
    }
    let runningAutoPlayInterval = null as null | number;
    if (autoPlay) {
      runningAutoPlayInterval = window.setTimeout(() => {
        console.log("AUTO PLAY", calcSliderPositionIndex(1), sliderItems);
        setSliderPositionIndex(calcSliderPositionIndex(1));
      }, autoPlayInterval);
    }

    return () => {
      if (autoPlay && runningAutoPlayInterval) {
        window.clearTimeout(runningAutoPlayInterval);
      }
    };
  }, [sliderItems, sliderPositionIndex]);

  const sliderNavigationAndControls = (
    <div
      className={classNames({
        [styles.sliderNavigationAndControls]: true,
        sliderNavigationAndControls: true,
        /** SliderNavigationAndControls Height reset **/
        [styles.sliderNavigationAndControlsHeightReset]:
          !!sliderNavigationAndControlsHeightReset,
        "visibly-hidden": !displayNavigation,
      })}
    >
      <button
        className={`slider-control ${styles.sliderControl} ${styles.prev} ${
          0 === sliderPositionIndex && !rotate && styles.disabled
        }`}
        onClick={() => {
          setSliderPositionIndex(calcSliderPositionIndex(-1));
        }}
      ></button>
      <button
        className={`slider-control ${styles.sliderControl} ${
          sliderItems.length - 1 <= sliderPositionIndex &&
          !rotate &&
          styles.disabled
        }`}
        onClick={() => {
          setSliderPositionIndex(calcSliderPositionIndex(1));
        }}
      ></button>
      {showProgressBar && (
        <div
          className={classNames({
            progress: true,
            [styles.progress]: true,
          })}
        >
          <div className={styles.progressRail} />
          <div
            className={styles.progressFill}
            style={{
              width: `${
                ((sliderPositionIndex + 1) / sliderNavigationItems.length) * 100
              }%`,
            }}
          />
        </div>
      )}
      {
        <ul
          className={classNames({
            sliderNavigation: true,
            [styles.sliderNavigation]: true,
            invisible: !showNavigationIfRequired,
          })}
        >
          {sliderNavigationItems.map((item: number, index: number) => {
            return (
              <li
                key={index}
                className={`${styles.sliderNavigationItem} ${
                  "white" === iconClass ? "white-bg" : "black-bg"
                } ${
                  index === sliderPositionIndex &&
                  styles.sliderNavigationItemActive
                }`}
                onClick={() => {
                  setSliderPositionIndex(index);
                }}
              >
                {index}
              </li>
            );
          })}
        </ul>
      }
    </div>
  );

  return (
    <>
      <div
        {...swipeHandlers}
        className={classNames({
          slider: true,
          [styles.slider]: true,
          [styles.sliderFadeOutOnExit]: fadeOutOnExit,
          [styles.swipe]: swipe,
          [className]: true,
          [styles.showOverflow]: !isCalculating && showOverflow,
        })}
        style={{
          // @ts-ignore
          "--slider-transition-duration": `${transitionDuration}ms`,
        }}
      >
        <div
          className={styles.sliderRail}
          style={{
            // @ts-ignore
            "--slider-position-index": sliderPositionIndex,
          }}
        >
          {sliderItems.map((sliderItem: any, index: number) => {
            return (
              <div
                key={Date.now() * Math.random()}
                className={`sliderItem ${styles.sliderItem} ${
                  index === sliderPositionIndex && styles.sliderItemActive
                }`}
                data-items={calculateItemsPerSlide()}
              >
                {sliderItem}
              </div>
            );
          })}
        </div>

        {navigationAndControlsInside && sliderNavigationAndControls}
      </div>

      {!navigationAndControlsInside && sliderNavigationAndControls}
    </>
  );
};

export default Slider;
