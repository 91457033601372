// extracted by mini-css-extract-plugin
export var arrowDownRight = "FormazioneParagraph-module--arrowDownRight--03I5G";
export var buttonContainer = "FormazioneParagraph-module--buttonContainer--cLbZv";
export var clock = "FormazioneParagraph-module--clock--0FXwH";
export var container = "FormazioneParagraph-module--container--vLbfu";
export var content = "FormazioneParagraph-module--content--JsROu";
export var headline = "FormazioneParagraph-module--headline--Q6w3E";
export var icon = "FormazioneParagraph-module--icon--ZFtk3";
export var iconAndHeadlineContainer = "FormazioneParagraph-module--iconAndHeadlineContainer--GkLni";
export var megafono = "FormazioneParagraph-module--megafono--5kKWA";
export var pig = "FormazioneParagraph-module--pig--eAPuB";
export var subTitle = "FormazioneParagraph-module--subTitle--Vr2PE";