import React from "react";
import Headline from "../../components/Headline/Headline";
import Icon from "../../components/Icon/Icon";

//@ts-ignore
import * as styles from "./IndexHeroParagraph.module.css";

const IndexHeroParagraph = () => {
  return (
    <div className={styles.container}>
      <div
        className={`
                    grid-8--mobile 
                    grid-8--tablet 
                    grid-12--desktop-small
                    ${styles.content}
                `}
      >
        {/* Headline */}
        <div className={styles.headlineContainer}>
          <Headline
            className={`
                        ${styles.headline}`}
            tagName="h1"
            headline="Ascoltare x Creare"
          />

          <div className={styles.businessArrow}>
            <Icon icon="businessArrow" />
            <span className={styles.sector}>Value</span>
          </div>

          <div className={styles.peopleArrow}>
            <Icon icon="peopleArrow" />
            <div>
              <span className={styles.sector}>People</span>
            </div>
          </div>

          <div className={styles.valueArrow}>
            <div className={styles.valueContainer}>
              <Icon icon="valueArrow" />
              <div className={styles.desktopCheck}>
                <span className={styles.sector}>Business</span>
              </div>
            </div>
          </div>

          <div className={styles.strettaDiMano}>
            <Icon icon="strettaDiMano" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndexHeroParagraph;
