import React from "react";
import { HeadlineTag } from "../../_typescript/type";
import Headline from "../../components/Headline/Headline";
import Slider from "../../components/Slider/Slider";

//@ts-ignore
import * as styles from "./HeaderSliderParagraph.module.css";

interface IHeaderSliderParagraph {
  tagName: HeadlineTag;
  headline: string;
  subTitle: string;
  items: [];
}

const HeaderSliderParagraph = ({
  tagName,
  headline,
  subTitle,
  items,
}: IHeaderSliderParagraph) => {
  return (
    <div className={styles.container}>
      {/* Headline */}
      <div className={styles.headlineSContainer}>
        <Headline
          className={styles.headline}
          tagName={tagName}
          headline={headline}
        />

        {/* Sub Title */}
        <span className={styles.subTitle}>{subTitle}</span>
      </div>
      {/* Slider Container */}
      <div className={`grid-12--desktop-small ${styles.sliderContainer}`}>
        <Slider
          items={items}
          desktopItemsPerSlide={5}
          tabletItemsPerSlide={4}
          mobileItemsPerSlide={2}
          fadeOutOnExit={false}
          rotate={true}
          swipe={true}
          sliderNavigationAndControlsHeightReset={true}
        />
      </div>
    </div>
  );
};

export default HeaderSliderParagraph;
