// extracted by mini-css-extract-plugin
export var creiamoValoreHeadline = "index-module--creiamoValoreHeadline--98Y1U";
export var creiamoValoreImage = "index-module--creiamoValoreImage--Z3zep";
export var creiamoValoreParagraph = "index-module--creiamoValoreParagraph--SRvf+";
export var headlineAndImageContainer = "index-module--headlineAndImageContainer--aiTAm";
export var headlineImageContainer = "index-module--headlineImageContainer--HEDc+";
export var iconBoldContainer = "index-module--iconBoldContainer--8YMns";
export var iconBoldLeftContainer = "index-module--iconBoldLeftContainer--iv8Iy";
export var paddingLeft = "index-module--paddingLeft--se6sG";
export var paragraphsContainer = "index-module--paragraphsContainer--yzsAU";
export var relativeParagraph = "index-module--relativeParagraph--WxiSx";
export var textAlignCenter = "index-module--textAlignCenter--taiOM";
export var uomoIdeaContainer = "index-module--uomoIdeaContainer--O8mr8";