import React from "react";
import Headline from "../../components/Headline/Headline";
import Button from "../../components/Button/Button";
import Icon from "../../components/Icon/Icon";

//@ts-ignore
import * as styles from "./FormazioneParagraph.module.css";

const FormazioneParagraph = () => {
  return (
    <section
      id="formazione"
      className={`grid-8--mobile grid-8--tablet grid-12--desktop-small ${styles.container}`}
    >
      <div
        className={`
                    grid-8--mobile
                    grid-6--tablet
                    grid-6--desktop-small
                    ${styles.content} 
                `}
      >
        <div className={styles.iconAndHeadlineContainer}>
          <Icon
            className={`${styles.icon} ${styles.megafono}`}
            icon="megafono"
          />
          {/* Headline */}
          <Headline
            className={`grid-6--mobile ${styles.headline}`}
            tagName="h2"
            headline="Formazione Manageriale"
          />
          <Icon className={`${styles.icon} ${styles.clock}`} icon="clock" />
        </div>

        {/* SubTitle */}
        <span className={styles.subTitle}>
          Corsi di formazione Manageriale?
        </span>

        <Icon className={`${styles.icon} ${styles.pig}`} icon="pig" />
        <div className={`grid-6--mobile ${styles.buttonContainer}`}>
          <Button
            layout="button"
            to="#contatti"
            label="Contatta il nostro team"
          />
        </div>
        <Icon
          className={`${styles.icon} ${styles.arrowDownRight}`}
          icon="arrowDownRight"
        />
      </div>
    </section>
  );
};

export default FormazioneParagraph;
