// extracted by mini-css-extract-plugin
export var businessArrow = "IndexHeroParagraph-module--businessArrow--q6irU";
export var container = "IndexHeroParagraph-module--container--pgief";
export var content = "IndexHeroParagraph-module--content--rGCDo";
export var desktopCheck = "IndexHeroParagraph-module--desktopCheck--PmOFb";
export var headline = "IndexHeroParagraph-module--headline--kKEhU";
export var headlineContainer = "IndexHeroParagraph-module--headlineContainer--kTgdD";
export var peopleArrow = "IndexHeroParagraph-module--peopleArrow--YB7++";
export var sector = "IndexHeroParagraph-module--sector--qIdOa";
export var strettaDiMano = "IndexHeroParagraph-module--strettaDiMano--eCtaB";
export var valueArrow = "IndexHeroParagraph-module--valueArrow--GSmPM";
export var valueContainer = "IndexHeroParagraph-module--valueContainer--kUyhZ";